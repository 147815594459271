import { useEffect, useState } from 'react';
import { Form, Button, Container, Row, Col, Spinner, Alert } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const [questionId, setQuestionId] = useState("df1a5a2d-d9ab-4830-8514-570ba27c9964");
  const [question, setQuestion] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [finalLink, setFinalLink] = useState(undefined);
  const [userInput, setUserInput] = useState('');
  const [error, setError] = useState(undefined);

  useEffect(() => {
    getQuestion(questionId);
  }, [questionId]);

  const getQuestion = (questionId) => {
    setLoading(true);
    setError(null);
    if (questionId === 'END') {
      setLoading(false);
      return;
    }
    fetch('https://getquestion-kryltctp6q-uc.a.run.app', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ questionId })
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setQuestion(data);
        setLoading(false);
      })
      .catch(error => {
        setError('Failed to fetch question. Please try again.');
        setLoading(false);
      });
  };

  const handleInputChange = (event) => {
    setUserInput(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    checkAnswer(questionId, userInput);
  };

  const checkAnswer = (questionId, answer) => {
    setLoading(true);
    fetch('https://checkanswer-kryltctp6q-uc.a.run.app', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ questionId, answer })
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setQuestionId(data.nextQuestion);
        if (data.nextQuestion === 'END') {
          setFinalLink(data.finalLink);
          setLoading(false);
          return;
        }
        setUserInput('');
        getQuestion(data.nextQuestion);
      })
      .catch(error => console.error('Error checking answer:', error));
  };

  if(finalLink) {
    return (
      <Container className="mt-5">
        <Row className="justify-content-md-center">
          <Col md={8}>
            <h1>You have reached the end of the game!</h1>
            <h2>Here is your <a href={finalLink}>reward</a></h2>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container className="mt-5">
      <Row className="justify-content-md-center">
        <Col md={8}>
          <h1 className="text-center mb-4">Birthday Hunt</h1>
          {loading ? (
            <div className="text-center">
              <Spinner animation="border" role="status" />
            </div>
          ) : error ? (
            <Alert variant="danger">{error}</Alert>
          ) : (
            <>
              <div dangerouslySetInnerHTML={{ __html: question.questionMain }} />
              <div className="mb-4">{question.questionInfo}</div>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="userInput">
                  <Form.Label>Enter your answer:</Form.Label>
                  <Form.Control
                    type="text"
                    value={userInput}
                    onChange={handleInputChange}
                    placeholder="Type your answer here"
                  />
                </Form.Group>
                <Button variant="primary" type="submit" className="mt-3">
                  Submit
                </Button>
              </Form>
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default App;
